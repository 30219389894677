const cards = [
  {
    name: "Team 1",
    icon: <i className='fas fa-users fa-lg'></i>,
    description:"Elite",
  },
  {
    name: "Team 2",
    icon: <i class="fas fa-users fa-lg"></i>,
    description:"Semi Elite",
  },
  {
    name: "Team 3",
    icon: <i class="fas fa-users fa-lg"></i>,
    description:"Experts",
  },
  {
    name: "Team 4",
    icon: <i class="fas fa-users fa-lg"></i>,
    description:"Operational",
  },
];
export default cards;