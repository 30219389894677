import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from "./footer.module.scss";

//react-icon
import { FiCheckSquare } from "react-icons/fi";

export const Footer = (props) => {
  let dark = props.theme;
  const [submited, setSubmited] = useState(false);
  //email state
  const [email, setEmail] = useState("");
  //setting email error
  const [emailErr, setEmailErr] = useState({});

  const emailValidation = (email) => {
    let isValid = true;
    const emailErr = {};
    // eslint-disable-next-line
    const emailRegex = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    if (!emailRegex.test(email)) {
      emailErr.emailRequired = "* Please enter valid Email";
      isValid = false;
    }
    if (!email.trim()) {
      emailErr.emailRequired = "* Email is required";
      isValid = false;
    }
    setEmailErr(emailErr);
    return isValid;
  };

  const validation = () => {
    let isValid = true;
    //email
    isValid = emailValidation(email);
    return isValid;
  };

  //setting up validations onChange
  const handleEmailChange = (e) => {
    let isValid = true;
    let email = e.target.value;
    setEmail(email);
    isValid = emailValidation(email);
    return isValid;
  };

  //handling submit
  const handleSubmit = (e) => {
    e.preventDefault();
    //if isValid = true, form submission trigger
    const isValid = validation();
    if (isValid) {
      setSubmited(true);
      //resetting email value in state after submission of form
      setEmail("");
    }
  };
  var date = new Date();
  var year = date.getFullYear();
  return (
    <React.Fragment>
      <footer
        className={
          dark
            ? `${style["footer"]} ${style["footer-dark"]}`
            : `${style["footer"]}`
        }
      >
        <div
          className={
            dark
              ? `${style["footer-dash"]} ${style["footer-dash-dark"]}`
              : `${style["footer-dash"]}`
          }
        >
          <div
            className={
              dark
                ? `${style["footer-text"]} ${style["footer-text-dark"]}`
                : `${style["footer-text"]}`
            }
          ></div>
        </div>
      </footer>

      <div
        className={
          dark
            ? `${style["cprt-text"]} ${style["cprt-text-dark"]}`
            : `${style["cprt-text"]}`
        }
      >
        <p className={`${style["cprt"]} py-2`}><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" >京ICP备2023004589号</a>Copyright © {year} REI FLY</p>
      </div>
    </React.Fragment>
  );
};
