import React from "react";

import styles from "./ContactUs.module.scss";

export const ContactUs = (props) => {
  let dark = props.theme;
  return (
    <div
      className={
        dark ? `${styles["home"]} ${styles["dark"]}` : `${styles["home"]}`
      }
    >
      <iframe
        id="dashboard-iframe"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        width="100%"
        height="2000"
        src="http://datagear.gxcfly.top:50401/dashboard/show/2e70c5b7218447f8f40b/"
      ></iframe>
    </div>
  );
};
